import { HttpClient } from "../HttpClient";
/**
 * * Delete Youtube Channel Information From User Profile
 */
export const DeleteUserSocialMediaPages = async (id, tableIdentifier) => {
  try {
    const { data } = await HttpClient.patch(`api/UserSocialMediaPages`, {
      Id: id,
      TableIdentifier: tableIdentifier,
    });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
