import { HttpClient } from "../HttpClient";
/**
 * * Get Youtube Channel Information List From User Profile
 */
export const GetUserSocialMediaPages = async (userId) => {
  try {
    const { data } = await HttpClient.get(
      `api/UserSocialMediaPages/List`,
      {
        params: {
          UserId: userId,
          rowsPerPage: 100,
        },
      }
    );
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
