import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import {
  fetchVideoDetails,
  fetchChannelDetails,
} from "@/helpers/youtubeScraper";
import { PostClaimsList } from "../services/Contents/PostClaimsList";
import { useUserDetailStore } from "@/store/userDetailStore";
import { PostYoutubeChannelInformation } from "@/services/YoutubeChannelInformation/PostYoutubeChannelInformation";

export const useYoutubeStore = defineStore("youtube", () => {
  const userDetailStore = useUserDetailStore();
  const { userDetail } = storeToRefs(userDetailStore);

  const youtubeVideo = ref({
    id: "",
    url: "",
    title: "",
    thumbnail: "",
    thumbnailWidth: "",
    viewCount: null,
    channelId: "",
    channelName: "",
    channelThumbnail: "",
  });
  const youtubeChannelList = ref([]);
  const selectedYoutubeChannelId = ref("GetFromVideo");
  const setYoutubeVideo = async (videoUrl) => {
    const videoResponse = await fetchVideoDetails(videoUrl);
    const channelDetails = await fetchChannelDetails(
      videoResponse.channelId
    );
    youtubeVideo.value = {
      id: videoResponse.videoId,
      url: videoUrl,
      title: videoResponse.title,
      thumbnail: videoResponse.thumbnailUrl,
      thumbnailWidth: videoResponse.thumbnailWidth,
      viewCount: videoResponse.viewCount,
      channelId: videoResponse.channelId,
      channelName: videoResponse.channelTitle,
      channelThumbnail: channelDetails.thumbnail,
    };
  };
  const resetYoutubeVideo = () => {
    youtubeVideo.value = {
      id: "",
      url: "",
      title: "",
      thumbnail: "",
      thumbnailWidth: "",
      viewCount: null,
      channelId: "",
    };
  };
  const resetYoutubeChannel = () => {
    selectedYoutubeChannelId.value = null;
  };
  const resetClipList = () => {
    clipList.value = [
      {
        clipTitle: "",
        startTime: "",
        endTime: "",
        clipURL: "",
        description: "",
        class: {
          clipTitle: "form-control",
          startTime: "form-control",
          endTime: "form-control",
          clipURL: "form-control",
        },
        errorMessage: {
          clipTitle: { class: "d-none", displayText: "" },
          startTime: { class: "d-none", displayText: "" },
          endTime: { class: "d-none", displayText: "" },
          clipURL: { class: "d-none", displayText: "" },
        },
      },
    ];
  };
  const ifHasUnsavedChanges = computed(
    () =>
      youtubeVideo.value.id !== "" ||
      youtubeVideo.value.url !== "" ||
      youtubeVideo.value.title !== "" ||
      youtubeVideo.value.thumbnail !== "" ||
      youtubeVideo.value.thumbnailWidth !== "" ||
      youtubeVideo.value.viewCount !== null
  );
  const clipList = ref([
    {
      clipTitle: "",
      startTime: "",
      endTime: "",
      clipURL: "",
      description: "",
      class: {
        clipTitle: "form-control",
        startTime: "form-control",
        endTime: "form-control",
        clipURL: "form-control",
      },
      errorMessage: {
        clipTitle: { class: "d-none", displayText: "" },
        startTime: { class: "d-none", displayText: "" },
        endTime: { class: "d-none", displayText: "" },
        clipURL: { class: "d-none", displayText: "" },
      },
    },
  ]);
  const getChannelNameFromSelection = computed(() => {
    return youtubeChannelList.value.find(
      (channel) => channel.channelId == selectedYoutubeChannelId.value
    );
  });
  const submitVideo = async () => {
    const parseClipList = clipList.value.map((claim) => {
      return {
        TimeStamp: `${claim.startTime}-${claim.endTime}`,
        clipLink: claim.clipURL,
        clipTitle: claim.clipTitle,
      };
    });
    const postBody = {
      youtubeChannel:
        selectedYoutubeChannelId.value == "GetFromVideo"
          ? youtubeVideo.value.channelName
          : getChannelNameFromSelection.value.name,
      youtubeUrl: youtubeVideo.value.url,
      AssetLabel: userDetail.value.assetLabelName,
      claims: parseClipList,
    };
    const submitResponse = await PostClaimsList(postBody);
    if (submitResponse) {
      if (selectedYoutubeChannelId.value == "GetFromVideo") {
        await PostYoutubeChannelInformation({
          channelId: youtubeVideo.value.channelId,
          name: youtubeVideo.value.channelName,
          thumbnail: youtubeVideo.value.channelThumbnail,
        });
      }
      return true;
    } else {
      return false;
    }
  };
  const isAdded = ref(false);
  const youtubeVideoDuration = ref(null);
  const convertToMinutes = (timeString) => {
    const parts = timeString.split(":").map(Number);
    let totalMinutes = 0;

    if (parts.length === 3) {
      const [hours, minutes, seconds] = parts;
      totalMinutes = hours * 60 + minutes + seconds / 60;
    } else if (parts.length === 2) {
      const [minutes, seconds] = parts;
      totalMinutes = minutes + seconds / 60;
    } else if (parts.length === 1) {
      const [seconds] = parts;
      totalMinutes = seconds / 60;
    }

    return totalMinutes;
  };
  const hasHour = (videoDuration) => {
    return videoDuration.split(":").length === 3;
  };
  return {
    youtubeVideo,
    youtubeChannelList,
    selectedYoutubeChannelId,
    setYoutubeVideo,
    resetYoutubeVideo,
    resetYoutubeChannel,
    resetClipList,
    ifHasUnsavedChanges,
    clipList,
    submitVideo,
    isAdded,
    youtubeVideoDuration,
    convertToMinutes,
    hasHour,
  };
});
